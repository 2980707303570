* {
    box-sizing: border-box;
}
a {
    text-decoration: none;
}
hr {
    border: none;
    margin-top: 10px;
    height: 1px;
    background-color: #c0c8c9;
    background-image: linear-gradient(to right, #fff 0%, #777 50%, #fff 100%);
}
body {
    margin: 0;
    font-family: "Roboto";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #fff;
    height: 100vh;
    overflow: hidden;
}

input,
textarea,
select {
    font-family: "Roboto";
    resize: none;
}
@font-face {
    font-family: "trenchthin";
    src: url("../fonts/trenchthin-az1j-webfont.woff2") format("woff2"),
        url("../fonts/trenchthin-az1j-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #df002790;
    border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
    background: #df0027;
}
.flex-row {
    display: flex;
}
.flex-column {
    display: flex;
    flex-direction: column;
}

#root {
    height: 100%;
    overflow: hidden;
}
#main-viewer {
    height: 100%;
    display: flex;
    flex-direction: column;
    .flex-main {
        height: 100%;
        flex: 1;
        overflow: hidden;
        display: flex;
        .pages {
            flex: 1;
            overflow: hidden;
        }
    }
}
.text-center {
    text-align: center;
}
.pointer {
    cursor: pointer;
}
.page {
    position: relative;
    padding: 20px;
    height: 100%;
    overflow-y: auto;
    .page-top-options {
        display: flex;
        .search-wrapper {
            flex: 1;
        }
    }
}

.fixed-page-tabs {
    position: fixed;
    top: 15px;
    right: 40px;
    z-index: 12;
    display: flex;
    .button {
        margin-left: 2px;
    }
}
.fixed-page-top {
    height: 60px;
    position: fixed;
    display: flex;
    align-items: center;
    top: 0px;
    right: 20px;
    z-index: 10;
    .button {
        margin-left: 20px;
    }
    .search-wrapper {
        flex: 1;
        width: 300px;
        margin-bottom: 0;
    }
}

.loading-icon {
    width: 30px;
    height: 30px;
    animation-name: rotate;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
