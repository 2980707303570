.table {
    width: 100%;
    font-size: 14px;
    border-collapse: collapse;
    background-color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: rgba(0, 0, 0, 0.73);
    overflow: hidden;
    .thead .tr {
        padding-right: 20px;
    }
    .tbody {
        flex: 1;
        overflow-y: scroll;
        padding-bottom: 48px;
    }
    .th {
        text-align: left;
        padding: 15px;
        font-weight: bold;
    }
    .tr {
        display: flex;
        border-bottom: 1px solid #ddd;
        width: 100%;
        padding: 0 10px 0 20px;
        position: relative;
        .td,
        .th {
            flex: 1 1 auto;
            padding: 0 8px;
            text-transform: capitalize;
            border: none;
            height: 48px;
            line-height: 48px;
            display: flex;
            overflow: hidden;
            align-items: center;
            position: relative;
            .inner {
                overflow: hidden;
                display: flex;
                align-items: center;
            }
            .text {
                display: block;
                line-height: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-right: 8px;
                b {
                    font-weight: 500;
                }
            }
            .button {
                padding: 5px 15px;
                background-color: transparent;
                border: none;
                cursor: pointer;
                color: inherit;
            }
            .td-flex {
                display: flex;
                align-items: center;
            }
            .button {
                box-shadow: none;
                &:active {
                    box-shadow: inset -2px -2px 8px 1px rgba(255, 255, 255, 0.2),
                        inset 2px 2px 8px 1px rgba(0, 0, 0, 0.4);
                }
            }
            &.xs {
                flex: 0 0 60px;
                box-sizing: border-box;
            }
            &.sm {
                flex: 0 0 150px;
                box-sizing: border-box;
            }
        }
        &.selected {
            background-color: #e5efff;
            //box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
            //z-index: 5;
            color: #000;
        }
        &.dark {
            background-color: #ccd;
            border-bottom-color: #aaa;
            font-size: 0.9em;
            .td {
                //padding: 8px 15px;
            }
        }
    }
}
