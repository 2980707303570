button {
    outline: none !important;
    cursor: pointer;
    background: transparent;
    position: relative;
    border: none;
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
}
.button {
    flex-direction: column;
    justify-content: center;
    text-transform: capitalize;
    align-items: center;
    padding: 15px 30px;
    overflow: hidden;
    background-color: purple;
    color: #fff;
    border-radius: 4px;
    box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.1),
        inset 4px 4px 8px 1px rgba(255, 255, 255, 0.1);
    &.green {
        background-color: #090;
    }
    &.light-grey {
        background-color: #ddd;
        color: #333;
    }
    &.grey {
        background-color: #555;
    }
    &.red {
        background-color: #900;
    }
    &.light-purple {
        background-color: rgb(141, 81, 238);
    }
    &.small {
        padding: 10px 15px;
    }
    &:active {
        box-shadow: inset -2px -2px 8px 1px rgba(255, 255, 255, 0.2),
            inset 2px 2px 8px 1px rgba(0, 0, 0, 0.4);
    }
    &.tab {
        background-color: #333;
        border-radius: 0;
        box-shadow: none;
        p {
            color: #fff;
        }
        &.selected {
            background-color: #eee;
            p {
                color: #333;
            }
        }
    }
}
