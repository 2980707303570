.input-wrapper,
.textarea-wrapper,
.toggle-wrapper,
.select-wrapper,
.date-wrapper,
.yes-no-wrapper,
.form-select-wrapper,
.list-creator-wrapper {
    position: relative;
    flex: 0 0 100%;
    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 10px;

    &.third {
        flex: 0 0 33%;
    }
    &.half {
        flex: 0 0 50%;
    }
    &.full {
        flex: 0 0 100%;
    }
    .label {
        display: block;
        color: #121212;
        font-size: 14px;
        margin-bottom: 5px;
        text-transform: capitalize;
    }
    .input {
        width: 100%;
        border: none;
        padding: 10px;
        font-size: 16px;
        border-radius: 4px;
        border: 1px solid #ddd;
        -webkit-appearance: none;
        outline: none !important;
        color: #212121;
        transition-duration: 0.2s;
        &::placeholder {
            color: #999;
        }
        &:focus {
            border-color: rgba(147, 42, 179, 0.8);
        }
    }
    &.error {
        .input {
            border-color: #f00;
        }
    }
    .error {
        color: red;
        display: flex;
        padding-top: 3px;
        .error-text {
            flex: 1;
            font-size: 0.9em;
        }
        .error-icon {
            height: 18px;
        }
    }
    .helper {
        margin: 0;
        margin-top: 5px;
        font-size: 0.8em;
        color: #999;
    }
    &.date-wrapper .select-wrapper {
        padding: 0;
    }
    &.paragraph {
        .input {
            min-height: 60px;
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    -webkit-text-fill-color: #222 !important;
}

.select-options {
    margin-top: 3px;
    background-color: #fff;
    box-shadow: 0px 5px 10px 1px rgba($color: #000000, $alpha: 0.2);
    color: #222;
    position: absolute;
    z-index: 10;
    width: 100%;
    display: none;
    max-height: 400px;
    overflow-y: auto;
    &.open {
        display: block;
    }
    .option {
        padding: 10px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        &:hover {
            background-color: #459;
            color: #fff;
        }
        p {
            white-space: nowrap;
            text-overflow: ellipsis;
            flex: 1;
            display: block;
            overflow: hidden;
            margin: 0;
            font-size: 0.9em;
        }
        &.empty {
            background-color: #bbb;
        }
        &.selected {
            background-color: #4e74db;
            color: #fff;
        }
    }
}
